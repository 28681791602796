export const USER_COOKIE_NAME = 'user';
export const FB_USER_COOKIE_NAME = 'fbuser';
export const GOOGLE_USER_COOKIE_NAME = 'guser';
export const APPLE_USER_COOKIE_NAME = 'auser';

export type LoginProvider = 'facebook' | 'google' | 'google-one-tap' | 'apple' | string;

export const LOGIN_PROVIDERS: Record<string, LoginProvider> = {
  FB: 'facebook',
  GOOGLE: 'google',
  GOOGLE_ONE_TAP: 'google-one-tap',
  APPLE: 'apple',
};

export const prefix: Record<LoginProvider, string> = {
  [LOGIN_PROVIDERS.FB]: '1_',
  [LOGIN_PROVIDERS.GOOGLE]: '2_',
  [LOGIN_PROVIDERS.GOOGLE_ONE_TAP]: '2_',
  [LOGIN_PROVIDERS.APPLE]: '4_',
};

const providerToCookieName: Record<LoginProvider, string> = {
  [LOGIN_PROVIDERS.FB]: FB_USER_COOKIE_NAME,
  [LOGIN_PROVIDERS.GOOGLE]: GOOGLE_USER_COOKIE_NAME,
  [LOGIN_PROVIDERS.GOOGLE_ONE_TAP]: GOOGLE_USER_COOKIE_NAME,
  [LOGIN_PROVIDERS.APPLE]: APPLE_USER_COOKIE_NAME,
};

export const getLoginProvider = (id: string): LoginProvider => {
  const [provider] = Object.entries(prefix).find(([, v]) => id?.startsWith(v)) ?? [];
  return provider?.startsWith(LOGIN_PROVIDERS.GOOGLE) ? LOGIN_PROVIDERS.GOOGLE : provider;
};

export const getCookieName = (provider: LoginProvider) => providerToCookieName[provider];

export const getUserCookieOptions = () => ({
  maxAge: 60 * 60 * 24 * 30, // 30 days,
  httpOnly: false, // true,
  secure: process.env.NODE_ENV === 'production',
  path: '/',
  sameSite: 'lax',
});

export default LOGIN_PROVIDERS;

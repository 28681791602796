import React from 'react';
import { getOrigin } from 'helpers/getOrigin';
import useUser from 'lib/useUser';
import { useRouter } from 'next/router';

const makeUrlPath = (...params: string[]): string => {
  const url = params.filter(Boolean).join('/');
  return `/${url}`;
};

const GoogleOneTap: React.FC = () => {
  const { locale } = useRouter();
  const { tournamentName, readOnlyUser, readOnlyLoginProvider } = useUser();

  const returnUrl = makeUrlPath(locale, tournamentName);

  const loginUri = `${getOrigin()}/api/auth/google-one-tap/callback`;
  const googleClientId: string = process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID;

  if (readOnlyUser && readOnlyLoginProvider !== 'google') {
    return null;
  }

  return (
    <div
      id="g_id_onload"
      data-client_id={googleClientId}
      data-login_uri={loginUri}
      data-auto_prompt="true"
      data-skip_prompt_cookie="user"
      data-state={returnUrl}
    />
  );
};

export default GoogleOneTap;

import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';
import { colors, fsizes } from '_constants/cssVars';
import { fontFace } from './FontFace.styles';

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${fontFace}

  * {
    font-family: Rubik, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body {
    -webkit-tap-highlight-color: transparent;
    color: ${colors.text};
    font-size: ${fsizes.regular};
    -webkit-overflow-scrolling: touch;
  }

  a {
    text-decoration: none;
    color: ${colors.text};
    cursor: pointer;
  }

  button {
    outline: none;
    border: 0;
    background: 0;
    cursor: pointer;
    padding: 0;
  }

  /* remove scrollbar */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export default GlobalStyles;

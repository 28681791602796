import { User } from 'lib/useUser';
import { useSelector } from 'react-redux';
import {
  hasLuckyLoserStage,
  hasRoundOf16Stage,
} from 'lib/configUtils';

export const I18N_COOKIE_NAME = 'locale';
type TeamPrediction = { id: string };
type GroupPrediction = { teams: TeamPrediction[] };
type KnockoutGamePrediction = {
  winner: string;
  home: { team: TeamPrediction };
  away: { team: TeamPrediction };
};

type LocalPredictions = {
  groupStage: GroupPrediction[];
  luckyLosers: GroupPrediction;
  roundOf16: KnockoutGamePrediction[];
  quarterfinals: KnockoutGamePrediction[];
  semifinals: KnockoutGamePrediction[];
  finals: KnockoutGamePrediction[];
};
type BackendGroupPrediction = {
  prediction: TeamPrediction[];
};
type BackendPredictionStructure = {
  user: User;
  groups: BackendGroupPrediction[];
  Best3rdThatAdvancesToKnockout: TeamPrediction[];
  roundOf16Winners: TeamPrediction[];
  quarterFinalWinners: TeamPrediction[];
  semiFinalWinners: TeamPrediction[];
  bronzeWinner: TeamPrediction;
  winner: TeamPrediction;
};

export const getBackendPredictionStructure = (
  userObj: User,
  predictions: LocalPredictions,
  tournamentConfig: any,
): BackendPredictionStructure => {
  const getWinnerId = (engagement) => (engagement.winner === 'home'
    ? engagement.home.team.id
    : engagement.away.team.id);

  const user = {
    ...userObj,
  };

  const groups = predictions.groupStage.map((group) => ({
    prediction: group.teams.map((team) => ({ id: team.id })),
  }));
  const Best3rdThatAdvancesToKnockout = predictions.luckyLosers.teams
    .slice(0, tournamentConfig.thirdPlaceAdvanceCount)
    .map((team) => ({
      id: team.id,
    }));
  const roundOf16Winners = predictions.roundOf16.map((engagement) => ({
    id: getWinnerId(engagement),
  }));
  const quarterFinalWinners = predictions.quarterfinals.map((engagement) => ({
    id: getWinnerId(engagement),
  }));
  const semiFinalWinners = predictions.semifinals.map((engagement) => ({
    id: getWinnerId(engagement),
  }));
  const [winner, bronzeWinner] = predictions.finals.map((engagement) => ({
    id: getWinnerId(engagement),
  }));

  return {
    user,
    groups,
    Best3rdThatAdvancesToKnockout,
    roundOf16Winners,
    quarterFinalWinners,
    semiFinalWinners,
    bronzeWinner,
    winner,
  };
};

export function getShareWheelUrl(wheelId: string, tournamentName: string) {
  return `https://predict.fotmob.com/${tournamentName}/wheel/${wheelId}`;
}

export function getWheelImgUrl(gameId: string, id: string) {
  return `https://s3-eu-west-1.amazonaws.com/fotmobapi/image_resources/prediction/wheel/${gameId}/${id}?ts=${Date.now()}`;
}

export const getTournamentImageUrl = (leagueId: string) => `https://images.fotmob.com/image_resources/logo/leaguelogo/${leagueId}.png`;

const getTournamentName = (config, tournamentId) => Object.keys(config.tournamentId).find(
  (key) => config.tournamentId[key] === tournamentId,
);

export const getPredictorUrl = (config, tournamentId, path = '') => `/${getTournamentName(config, tournamentId)}/${path}`;

interface TournamentConfig {
  games: { id: string, loginStep?: number, name?: string }[];
}

export const getPredictorLoginStepUrl = (config: TournamentConfig, tournamentId: string) => {
  const baseUrl = `/${getTournamentName(config, tournamentId)}`;

  // TODO: For world cup/others -> Resolve the last step from the config, based on the tournament structure etc...
  const { loginStep } = config.games
    .find(({ id }) => id === tournamentId) ?? {};

  if (!loginStep) {
    return baseUrl;
  }
  return `${baseUrl}/step/${loginStep}`;
};

export const getLSUserPredictionsKey = (tournamentId: string) => `userPredictions:${tournamentId}`;

export function userPredictionIsNotComplete(predictions, config) {
  const {
    groupStage,
    luckyLosers,
    roundOf16,
    quarterfinals,
    semifinals,
    finals,
  } = predictions;

  if (config.tablePrediction) {
    return groupStage.length === 0;
  }

  if (hasLuckyLoserStage(config) && luckyLosers.length === 0) {
    return true;
  }

  if (hasRoundOf16Stage(config) && roundOf16.length === 0) {
    return true;
  }

  return groupStage.length === 0
    || quarterfinals.length === 0
    || semifinals.length === 0
    || finals.length === 0;
}

interface Predictions {
  luckyLosers: {
    teams: never[];
  }
}

export function trimLuckyLosers(predictions: Predictions, tournamentConfig): Predictions {
  return {
    ...predictions,
    luckyLosers: {
      ...predictions.luckyLosers,
      teams: predictions.luckyLosers.teams.slice(
        0,
        tournamentConfig.thirdPlaceAdvanceCount,
      ),
    },
  };
}

export const useGetPredictedWinnerId = () => {
  const config = useSelector((state: any) => state.tournament);
  const savedPredictedWinner = useSelector(
    (state: any) => state.userCompletedPredictions?.predictions?.winner?.id,
  );

  let currentPrediction = useSelector((state: any) => state.userPrediction);
  if (userPredictionIsNotComplete(currentPrediction, config)) {
    const pr = typeof window !== 'undefined' && window.localStorage.getItem(getLSUserPredictionsKey(config.tournamentId));
    if (pr) {
      currentPrediction = JSON.parse(pr);
    }
  }
  const finals = currentPrediction?.finals ?? [];
  const final = finals[0] || {};
  const localPredictedWinner = (final.winner === 'home')
    ? final.home?.team?.id
    : final.away?.team?.id;

  return savedPredictedWinner || localPredictedWinner || '';
};

import { useContext } from 'react';
import { PredictorUserContext } from '_constants/contexts';
import { LoginProvider } from './auth/loginProviders';

export type User = {
  id: string;
  tournamentId: string;
  tournamentName: string;
  loggedIn: boolean;
  readOnlyUser: boolean;
  readOnlyLoginProvider?: LoginProvider;
  name?: string;
  accessToken?: string;
  imageUrl?: string;
  email?: string;
  token?: string;
};

const useUser = (): User => useContext(PredictorUserContext) as User;

export default useUser;

export const getTournamentConfig = (state: any) => state.tournament;
export const getUserPrediction = (state: any) => state.userPrediction;

export const getIsSavingPredictions = (state: any) => state
  .userCompletedPredictions
  .saving === true;
export const getIsSavingPredictionsDone = (state: any) => state
  .userCompletedPredictions
  .savingDone === true;
export const getIsSavingPredictionsError = (state: any) => state
  .userCompletedPredictions
  .saveError;
export const getIsGettingPredictions = (state: any) => state
  .userCompletedPredictions
  .fetching === true;
export const getIsGettingPredictionsDone = (state: any) => state
  .userCompletedPredictions
  .fetchingDone === true;
export const getIsGettingPredictionsError = (state: any) => state
  .userCompletedPredictions
  .error;

export const getUserCompletedPredictionsSelector = (state: any) => state
  .userCompletedPredictions.predictions;

export const getIsGettingWheelUrl = (state: any) => state.userWheel?.fetching === true;
export const getIsGettingWheelUrlDone = (state: any) => state.userWheel?.fetchingDone === true;
export const getWheelUrl = (state: any) => state.userWheel?.wheel;

export const getHasFetchedTranslations = (state: any): boolean => state.translations?.fetched
  ?? false;

export const getHasFetchedTourmentConfig = (state: any): boolean => getTournamentConfig(state)
  ?.fetched ?? false;

export const getHasFetchedLocation = (state: any): boolean => state.location?.fetched || false;

export const getHasFetchedOdds = (state: any): boolean => state.server?.odds?.fetched
  || state.odds?.fetched
  || false;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RootPageBackgroundContainer = styled.div`
  background: #f5f5f5;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 50px 36px;
`;

const RootPage = ({ children }) => (
  <RootPageBackgroundContainer>
    { children }
  </RootPageBackgroundContainer>
);

RootPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

RootPage.defaultProps = {
  children: null,
};

export default RootPage;

import React, { useCallback, useContext, useState } from 'react';

import PSPScreenInfo from '../ScreenInfo/index';
import PredictorLoginComponent from '../../Pages/AllSetPage/LoginComponent/index';

type SetFunction<T> = (value: T) => void;
type LoginModalOptions = boolean | {
  open: boolean;
  allSetText?: string;
  signInRequiredText?: string;
  submit?: boolean;
};

const defaultValue: SetFunction<LoginModalOptions> = (_value: LoginModalOptions) => {};
const LoginModalContext = React.createContext<SetFunction<LoginModalOptions>>(defaultValue);

type Props = {
  children: React.ReactNode | React.ReactNode[];
}

const LoginModalProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [allSetText, setAllSetText] = useState<string>('');
  const [signInRequiredText, setSignInRequiredText] = useState<string>('');
  const [shouldSubmit, setShouldSubit] = useState(false);

  const callback = useCallback<SetFunction<LoginModalOptions>>((options) => {
    if (typeof options === 'boolean') {
      setIsOpen(options);
    } else {
      setIsOpen(options?.open);
      setAllSetText(options?.allSetText);
      setSignInRequiredText(options?.signInRequiredText);
      setShouldSubit(options?.submit);
    }
  }, []);

  return (
    <LoginModalContext.Provider value={callback}>
      { children }
      <PSPScreenInfo
        isOpen={isOpen}
        close={() => setIsOpen(false)}
      >
        <PredictorLoginComponent
          submitAfterLogin={shouldSubmit}
          goStraightToWheel={!shouldSubmit}
          allSetText={allSetText}
          signInRequiredText={signInRequiredText}
        />
      </PSPScreenInfo>
    </LoginModalContext.Provider>
  );
};

export const useLoginModal = () => useContext(LoginModalContext);

export default LoginModalProvider;

import { useCallback } from 'react';
import { getUserPrediction, getTournamentConfig } from '_store/selectors';
import { useSelector } from 'react-redux';
import {
  getLSUserPredictionsKey,
  userPredictionIsNotComplete,
  trimLuckyLosers,
} from '../helpers/index';

const useStoreLocalPrediction = () => {
  const localPredictions = useSelector(getUserPrediction);
  const tournamentConfig = useSelector(getTournamentConfig);

  const saveProgress = useCallback(() => {
    if (
      !userPredictionIsNotComplete(
        localPredictions,
        tournamentConfig,
      )
    ) {
      window.localStorage.setItem(
        getLSUserPredictionsKey(tournamentConfig.tournamentId),
        JSON.stringify(trimLuckyLosers(localPredictions, tournamentConfig)),
      );
    }
  }, [tournamentConfig, localPredictions]);

  return saveProgress;
};

export default useStoreLocalPrediction;

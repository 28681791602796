import identity from 'lodash/identity';
import { TrackJS } from './trackjs-isomorphic';

const isTrackJsEnabled = () => {
  const useTrackJs = (typeof window !== 'undefined')
    ? window.__USE_TRACKJS__ // eslint-disable-line no-underscore-dangle
    : process.env.USE_TRACKJS;

  return useTrackJs === 'true';
};

const trackJSApplication = (typeof window !== 'undefined')
  ? window.__TRACKJS_APPLICATION__ // eslint-disable-line no-underscore-dangle
  : process.env.TRACKJS_APPLICATION;

const InstallTrackJS = () => {
  if (!isTrackJsEnabled()) {
    return identity;
  }

  if (!TrackJS.isInstalled()) {
    TrackJS.install({
      token: 'dbe088481d1c4528aa21c652a9eb82d7',
      version: process.env.NEXT_PUBLIC_VERSION || 'local',
      application: trackJSApplication,
    });
  }

  return TrackJS.track;
};

export const track = (...args) => {
  if (!TrackJS.isInstalled()) {
    return;
  }

  TrackJS.track(...args);
};

export default InstallTrackJS;

import { clearPreviousLogins } from 'lib/auth/login';
import jwtDecode from 'jwt-decode';

const isExpiredOrExpiringWithin30min = (token) => {
  let decoded;
  try {
    decoded = jwtDecode(token);
  } catch (error) {
    // assume its ok if we cant decode the token
    return false;
  }

  const { exp } = decoded || {};
  if (!exp) {
    // if we cant find an exp-date on the decoded token, assume its ok
    return false;
  }

  const expireDate = new Date(exp * 1000);
  const in30min = new Date(new Date().getTime() + 30 * 60 * 1000);
  return expireDate < in30min;
};

const autologin = (navigate, { provider, ...data }) => {
  clearPreviousLogins();

  if (isExpiredOrExpiringWithin30min(data.token)) {
    // dont login if the token is about to expire
    return;
  }

  navigate({
    pathname: `/api/auth/${provider}/autologin`,
    query: {
      ...data,
      id: data.user,
    },
  });
};

export default autologin;

import { clearPreviousLogins } from './login';

export const clearLogin = clearPreviousLogins;

export const clearStorage = () => {
  if ((typeof localStorage !== 'undefined') && (typeof sessionStorage !== 'undefined')) {
    // LocalStorage
    localStorage.clear();

    // SessionStorage
    sessionStorage.clear();
  }
};

export function logout() {
  clearPreviousLogins();
  clearStorage();
}

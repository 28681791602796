import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const config = {
  apiKey: 'AIzaSyCZqywGGm48gpl5R8qfz0YhXuY8vi7AH1Y',
  authDomain: 'fotmobapp.firebaseapp.com',
  databaseURL: 'https://fotmobapp.firebaseio.com',
  projectId: 'fotmobapp',
  storageBucket: 'fotmobapp.appspot.com',
  messagingSenderId: '464272262888',
  appId: '1:464272262888:web:68f57911d4d05a0daaeb5d',
  measurementId: 'G-TW5QHD6NBV',
};

export const initializeFirebase = () => {
  if (typeof window !== 'undefined') {
    const app = initializeApp(config);
    getAnalytics(app);
  }
};

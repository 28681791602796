import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import { PredictorUserContext } from '_constants/contexts';
import { getCognitoProfile } from '_utils/login';
import PredictorSpinner from 'components/Spinner/predictorSpinner';
import GoogleOneTap from 'lib/auth/OneTapComponent';
import { User } from 'lib/useUser';
import useJoinLeague from 'lib/joinleague/useJoinLeague';
import { useConfig } from 'lib/useConfig';
import { useResolvePredictorTheme } from 'lib/usePredictorTheme';
import { createUserObject, resolveCurrentUserData } from './helpers';
import { PredictorGlobalStyles } from './predictorGlobalStyles';
import LogoutModal from './LogoutModalProvider';
import LoginModal from './LoginModalProvider';
import useFetchStuff from './useFetchStuff';

type QueryParams = {
  game: string;
  userId?: string;
};

type Props = {
  children: React.ReactNode;
};

const getPrefferedLocale = (locales: string[]): string => {
  const userLanguage = navigator.language ?? navigator.languages?.[0];
  const userLocale = locales.find((locale) => userLanguage.includes(locale));
  return userLocale;
};

const useRedirectToLocale = (): void => {
  const {
    defaultLocale, locale, locales, replace, pathname, asPath,
  } = useRouter();

  useEffect(() => {
    // If locale is not default, do nothing
    if (locale !== defaultLocale) {
      return;
    }

    // If there are no preffered locale, or the preffered locale is the default locale, do nothing
    const preffedLocale = getPrefferedLocale(locales);
    if (!preffedLocale || preffedLocale.includes(defaultLocale)) {
      return;
    }

    replace(pathname, asPath, { locale: preffedLocale });
  }, [locale, locales]);
};

const PredictorContainer: React.FC<Props> = ({
  children,
}) => {
  const router = useRouter();
  const { game: tournamentName } = router.query as QueryParams;

  useRedirectToLocale();

  useFetchStuff();
  const theme = useResolvePredictorTheme();

  const fetchingConfig = useSelector((state: any) => state.tournament.fetching === true);
  const predictorConfig = useConfig();
  const tournamentId = predictorConfig.tournamentId[tournamentName];

  const {
    readOnlyUserId,
    userCookie,
    hasUserCookies,
  } = resolveCurrentUserData(router.query);

  const cognitoProfile = getCognitoProfile();

  const providedUser: User = useMemo(() => createUserObject(
    tournamentId,
    tournamentName,
    router.locale,
    readOnlyUserId,
    hasUserCookies,
    userCookie,
    cognitoProfile.fbToken,
  ), [
    tournamentId,
    tournamentName,
    router.locale,
    userCookie.id,
    userCookie.name,
    userCookie.email,
    cognitoProfile.fbToken,
    hasUserCookies,
    readOnlyUserId,
  ]);

  useJoinLeague(providedUser);

  return (
    <>
      <PredictorGlobalStyles />
      <PredictorUserContext.Provider value={providedUser}>
        <ThemeProvider theme={theme}>
          <LoginModal>
            <LogoutModal>
              <GoogleOneTap />
              <PredictorSpinner show={fetchingConfig}>
                {children}
              </PredictorSpinner>
            </LogoutModal>
          </LoginModal>
        </ThemeProvider>
      </PredictorUserContext.Provider>
    </>
  );
};

export default PredictorContainer;

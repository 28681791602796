import axios from 'axios';
import { basePredictorApiUrl, baseWheelApiUrl } from 'infrastructur/backend';
import { clearLogin } from 'lib/auth/logout';

const localeMapping = {
  nb: 'no',
  default: 'en',
};

const getLocationUrlPart = (location) => {
  const { countryCode, regionId } = location || {};
  const urlPart = (countryCode === 'US')
    ? `${countryCode}_${regionId}`
    : countryCode;

  return urlPart?.toUpperCase();
};

const getLocale = (l) => localeMapping[l] ?? l;

const handleError = (prefix, dontLogStatuses = []) => ({ response, message }) => {
  const { status } = response || {};
  if (!dontLogStatuses.includes(status)) {
    console.error(prefix, message);
  }

  if ([400, 401].includes(status)) {
    clearLogin();
  }

  const error = new Error(message);
  error.status = status;
  throw error;
};

const handleErrorIgnore404 = (prefix) => handleError(prefix, [404]);

// used by backend + frontend?
export const getTournaments = () => axios
  .get(`${basePredictorApiUrl}/config`)
  .then((response) => response.data)
  // .then(() => fallbackConfig)
  .catch(({ message }) => {
    console.error('error in getTournaments', message);
  });

export const getTranslations = (locale) => axios
  .get(`https://data.fotmob.com/translations/${getLocale(locale)}/translation.json`)
  .then((response) => response.data)
  .catch(({ message }) => console.error('error in getTranslations', message));

export const getOdds = (location) => axios
  .get(`https://data.fotmob.com/predictor/${getLocationUrlPart(location)}/odds.json`)
  .then((response) => response.data)
  .catch(({ message }) => console.error('error in getOdds', message));

export function getTournamentConfig(tournamentId) {
  return axios
    .get(`${basePredictorApiUrl}/prediction/game/${tournamentId}/config`)
    .then((response) => response.data)
    .catch(({ message }) => console.error('error in getTournamentConfig', message));
}

export function getWheelUrlReq(payload) {
  const { tournamentId } = payload.user;
  return axios
    .post(`${baseWheelApiUrl}/${tournamentId}/wheel`, payload)
    .then((response) => response.data)
    .then((data) => {
      const url = data.replace(
        'https://images.fotmob.com/',
        'https://s3-eu-west-1.amazonaws.com/fotmobapi/',
      );
      return `${url.substr(0, url.indexOf('?ts'))}?ts=${Date.now()}`;
    })
    .catch(handleError('error in getWheelUrlReq'));
}

export function getPredictedWinner(payload) {
  const { tournamentId, id } = payload;
  return axios
    .get(`${basePredictorApiUrl}/prediction/game/${tournamentId}?userId=${id}`)
    .then((response) => response.data)
    .then((data) => data?.winner ?? {})
    .catch(({ message }) => console.error(`error in getPredictedWinner: ${message}`));
}

export const getLocation = () => axios
  .get('https://pub.fotmob.com/prod/pub/odds/mylocation')
  .then((response) => response.data)
  .catch(handleError('error in getLocation'));

export function saveUserPredictionsReq(data) {
  return axios
    .post(`/api/prediction/${data.user.tournamentId}`, data)
    .catch(handleError('error in saveUserPredictionsReq'));
}

export function getUserPredictionsReq(user) {
  return axios
    .get(`/api/prediction/${user.tournamentId}?userId=${user.id}`)
    .then((response) => response.data)
    .catch(handleErrorIgnore404('error in getUserPredictionsReq'));
}

export function createLeagueReq({ leagueName, tournamentId, token }) {
  const data = {
    name: leagueName,
  };
  return axios
    .post(`/api/league/${tournamentId}?token=${token}`, data)
    .then((response) => response.data)
    .catch(handleError('error in createLeagueReq'));
}

export function predictorGetUserReq(params) {
  const { tournamentId, id } = params;
  return axios
    .get(`/api/league/${tournamentId}?userId=${id}`)
    .then((response) => response.data)
    .catch(handleError('error in predictorGetUserReq'));
}

export function getSingleLeagueReq(obj) {
  const {
    leagueId, tournamentId, id,
  } = obj;
  return axios
    .get(`/api/league/${tournamentId}/${leagueId}?userId=${id}`)
    .then((response) => response.data)
    .catch(handleError('error in getSingleLeagueReq'));
}

export function leaveLeagueReq(obj) {
  const { tournamentId, leagueId, token } = obj;
  return axios
    .post(`/api/league/${tournamentId}/${leagueId}/leave?token=${token}`)
    .then((response) => response.data)
    .catch(handleError('error in leaveLeagueReq'));
}

export function joinLeagueReq(obj) {
  const { leagueCode, tournamentId, token } = obj;
  return axios
    .post(`/api/league/${tournamentId}/${leagueCode}?token=${token}`)
    .then((response) => response.data)
    .catch(handleError('error in joinLeagueReq'));
}

import styled from 'styled-components';
import { colors } from '_constants/cssVars';
import { animated } from 'react-spring';

export const PSPScreenInfoWrapper = styled(animated.section)`
  background: ${colors.white};
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  min-height: ${(p) => (p.minheight ? `${p.minheight}px` : 'auto')};
  z-index: 101;
`;

export const PSPScreenInfoContainer = styled.section`
  overflow-y: auto;
  padding: 32px 30px;
  color: ${colors.text};
  padding-bottom: 50px;
`;

export const PSIHeader = styled.h3`
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
  color: ${(p) => p.theme.main1};
`;

export const PSIHeaderBlack = styled(PSIHeader)`
  width: 215px;
  color: ${colors.text};
  margin-top: 60px;
  padding-bottom: 30px;
  text-align: left;
  width: 300px;
`;

export const PSIDescription = styled.p`
  margin-top: 19px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.15;
`;

export const PSIList = styled.ul`
  margin-top: 19px;
  list-style: disc;
  padding-left: 15px;
`;

export const PSIListItem = styled.li`
  font-size: 13px;
  line-height: 1.85;
`;

export const PSICloseButton = styled.button`
  background: url("/img/round-close-24px.svg") 100%/100% no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
`;

import styled from 'styled-components';

export const ErrorSection = styled.section`
  display: grid;
  place-content: center;
  height: 100vh;
`;

export const ErrorHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

export const ErrorParagraph = styled.p`
  max-width: 60ch;
  line-height: 16px;
`;

export const ErrorMail = styled.a`
  font-weight: bold;
`;

export const ErrorReturn = styled.button`
  margin-top: 20px;
`;

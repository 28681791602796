import {
  put, fork,
} from 'redux-saga/effects';

import {
  getWheelUrl,
  saveUserPredictions,
  getUserPredictions,
  createLeague,
  predictorGetUser,
  getSingleLeagueSaga,
  leaveLeagueSaga,
  joinLeagueSaga,
  tournamentTaskClient,
  translationsTaskClient,
  oddsTaskClient,
  locationTaskClient,
  predictedWinnerTaskBackend,
  tournamentTaskBackend,
  translationsTaskBacken,
} from './tournamentSagas';

export function* clientSaga() {
  try {
    yield fork(translationsTaskClient);
    yield fork(oddsTaskClient);
    yield fork(locationTaskClient);
    yield fork(tournamentTaskClient);
    yield fork(getWheelUrl);
    yield fork(saveUserPredictions);
    yield fork(getUserPredictions);
    yield fork(createLeague);
    yield fork(predictorGetUser);
    yield fork(getSingleLeagueSaga);
    yield fork(leaveLeagueSaga);
    yield fork(joinLeagueSaga);
  } catch (err) {
    const { message = 'something went wrong in mainSaga' } = err;
    yield put('MAIN_SAGA_ERROR', message);
  }
}

export function* serverSaga() {
  try {
    yield fork(translationsTaskBacken);
    yield fork(tournamentTaskBackend);
    yield fork(predictedWinnerTaskBackend);
  } catch (err) {
    const { message = 'something went wrong in serverSaga' } = err;
    yield put('SERVER_SAGA_ERROR', message);
  }
}

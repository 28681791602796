import React, { useCallback } from 'react';
import {
  PSocialMediaIcon,
  PFacebookButton,
  PGoogleButton,
  PAppleButton,
} from 'components/Predictor/Components/shared.styles';
import { login, LOGIN_PROVIDERS } from 'lib/auth/login';
import { useTranslation } from 'lib/i18n/useTranslation';
import { AllSetText } from '../allSetPage.styles';
import { useRedirLocation } from './helpers';
import useStoreLocalPrediction from '../useStoreLocalPrediction';

type TextForLoginComponentProps = {
  allSetText: string;
  signInRequiredText: string;
};

const TextForLoginComponent: React.FC<TextForLoginComponentProps> = ({
  allSetText,
  signInRequiredText,
}) => {
  const { t } = useTranslation();
  if (allSetText || signInRequiredText) {
    return (
      <AllSetText>
        <p>{allSetText}</p>
        <p>{signInRequiredText}</p>
      </AllSetText>
    );
  }
  return (
    <AllSetText>
      {t('logincomponent_desc1_v2')}
    </AllSetText>
  );
};

type PredictorLoginComponentProps = {
  submitAfterLogin?: boolean;
  goStraightToWheel?: boolean;
  allSetText?: string;
  signInRequiredText?: string;
};

const PredictorLoginComponent: React.FC<PredictorLoginComponentProps> = ({
  submitAfterLogin,
  goStraightToWheel,
  allSetText,
  signInRequiredText,
}) => {
  const redirLocation = useRedirLocation(submitAfterLogin, goStraightToWheel);
  const { t } = useTranslation();
  const saveProgress = useStoreLocalPrediction();

  const getProviderOnClick = useCallback((provider) => () => {
    saveProgress();
    login(provider, redirLocation);
  }, [redirLocation]);

  return (
    <section>
      <TextForLoginComponent
        allSetText={allSetText}
        signInRequiredText={signInRequiredText}
      />
      <PFacebookButton onClick={getProviderOnClick(LOGIN_PROVIDERS.FB)}>
        <PSocialMediaIcon />
        {t('sign_in_with_facebook')}
      </PFacebookButton>
      <PGoogleButton onClick={getProviderOnClick(LOGIN_PROVIDERS.GOOGLE)}>
        <PSocialMediaIcon />
        {t('sign_in_with_google')}
      </PGoogleButton>
      <PAppleButton onClick={getProviderOnClick(LOGIN_PROVIDERS.APPLE)}>
        <PSocialMediaIcon />
        {t('sign_in_with_apple')}
      </PAppleButton>
    </section>
  );
};

export default PredictorLoginComponent;

import PropTypes from 'prop-types';
import { compose } from 'redux';
import { appWithTranslation } from 'next-i18next';
import { wrapper } from 'state/index';
import installTrackJS from 'lib/trackjs';
import PredictorContainer from 'components/Predictor/Components/PredictorContainer';
import useAutologinByMessage from 'lib/autologin/useAutologinByMessage';
import { initializeFirebase } from 'lib/firebase';
import { Provider } from 'react-redux';
import { GlobalStyles } from '../../styles/Global.styles';

const trackError = installTrackJS();
initializeFirebase();

const MyApp = ({ Component, ...rest }) => {
  useAutologinByMessage();

  const { store, props } = wrapper.useWrappedStore(rest);
  const { pageProps } = props;

  return (
    <Provider store={store}>
      <PredictorContainer>
        <GlobalStyles />
        <Component {...pageProps} />
      </PredictorContainer>
    </Provider>
  );
};

MyApp.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape({}).isRequired,
};

MyApp.componentDidCatch = (error) => {
  trackError(error);
};

export default compose(
  appWithTranslation,
)(MyApp);

import { useSelector } from 'react-redux';

export type Game = {
  id: string;
  name: string;
  title: string;
  iconUrl: string;
  backgroundUrl: string;
  completed: boolean;
  configUrl: string;

  shortTitle?: string;
  version?: string;
  loginStep?: number;
  promoImg?: string;
  mustNotBeNamed?: boolean;
}

type ConfigType = {
  tournamentId: Record<string, string>;
  games: Game[];
};

type StateType = {
  server: {
    config: ConfigType;
  },
  config: ConfigType;
}

export const useConfig = (): ConfigType => {
  const config = useSelector<StateType, ConfigType>((state) => state.server.config);
  return config ?? { games: [], tournamentId: {} };
};

import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

export const BGOverlay = styled(animated.div)`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
`;

export const blackLogoStyles = css`
  background: url("/img/ic_fotmob_logo_white.svg") 100%/100% no-repeat;
  width: 165px;
  height: 28px;
`;

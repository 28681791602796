import { getLoginProvider } from 'lib/auth/loginProviders';
import { logout } from 'lib/auth/logout';
import { User } from 'lib/useUser';
import { ParsedUrlQuery } from 'querystring';
import { v4 as uuidv4 } from 'uuid';
import { getUser, isLoggedIn } from '_utils/login';
import { getUserIdFromQuery, readReadOnlyUserId, storeReadOnlyUserId } from './readOnlyUserHelpers';

export function getNameFromEmail(email: string): string {
  return email.substring(0, email.indexOf('@'));
}

type UserCookie = {
  displayImage?: string;
  id?: string;
  name?: string;
  email?: string;
  image?: string;
  token?: string;
};

type ResolvedUserInfo = {
  readOnlyUserId?: string;
  hasUserCookies: boolean;
  userCookie: UserCookie;
};

export const resolveCurrentUserData = (query: ParsedUrlQuery): ResolvedUserInfo => {
  const hasUserCookies = isLoggedIn();
  const userCookie = getUser();

  const queryUserId = getUserIdFromQuery(query);
  if (queryUserId) {
    if (hasUserCookies && (queryUserId !== userCookie.id)) {
      // Loggedin with different user. Logging out
      logout();
    }

    storeReadOnlyUserId(queryUserId);
  }

  return {
    readOnlyUserId: queryUserId || readReadOnlyUserId(),
    hasUserCookies: isLoggedIn(),
    userCookie: getUser(),
  };
};

export const createUserObject = (
  tournamentId: string,
  tournamentName: string,
  locale: string,
  readOnlyUserId: string,
  hasUserCookies: boolean,
  userCookie: UserCookie,
  fbToken: string,
): User => {
  const baseUser = {
    id: uuidv4(),
    tournamentId,
    tournamentName,
    locale,
    loggedIn: false,
    readOnlyUser: false,
  };

  const readOnlyUser = readOnlyUserId ? {
    id: readOnlyUserId,
    loggedIn: true,
    readOnlyUser: true,
    readOnlyLoginProvider: getLoginProvider(readOnlyUserId),
  } : {};

  const loggedInUser = hasUserCookies ? {
    id: userCookie.id,
    loggedIn: hasUserCookies,
    readOnlyUser: false,

    name: userCookie.name ? userCookie.name : getNameFromEmail(userCookie.email ?? ''),
    accessToken: fbToken,
    imageUrl: userCookie.image,
    email: userCookie.email,

    token: userCookie.token,
  } : {};

  return {
    ...baseUser,
    ...readOnlyUser,
    ...loggedInUser,
  };
};

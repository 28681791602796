import { useRouter } from 'next/router';
import { useConfig } from './useConfig';

export const useTournamentIdServer = (query) => {
  const { game } = query;
  const config = useConfig();
  return config?.tournamentId?.[game];
};

export const useTournamentId = () => {
  const router = useRouter();
  return useTournamentIdServer(router.query);
};

export default useTournamentId;

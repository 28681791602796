const euroTheme = {
  // backgroundUrl: ['/img/wc2022-background@2x.webp', '/img/wc2022-background@3x.webp'],
  // backgroundUrlMobile: ['/img/wc2022-m-background@2x.webp', '/img/wc2022.m-background@3x.webp'],

  cellphoneImg: '/img/euro-phone.png',
  followInTheAppPhoneImage: '/img/euro-follow.png',
  logoColor: '#000000',
  startPageText: '#eeeee5',
  cardColor: '#1042DB',
  main1: '#0b3399',
  main2: '#1042DB',

  btnBg1: '#01BD66',
  btnBg2: '#1049db',
  btnBg3: '#01BD66',
  btnBg4: '#1049db',

  btnText1: '#ffffff',
  activeText: '#eeeee5',

  redLinePos: '173px',
  headerBg2: '#802239',
  background2: '#F5F5F5',
  spinnerColor: '#0b3399',
  text: '#222222',
  text2: '#222222',
  iconColors: {
    left: '#138b9d',
    right: '#1db4cb',
  },
  hover: {
    cardColor: '#285bdf',
    btnBg1: '#019752',
    btnBg2: '#285bdf',
    btnBg3: '#019752',
    btnBg4: '#285bdf',
  },

  steps: {
    visited: '#989898',
  },
  errorBackground: '#ed3451',
  sponsored2: '#a2a2a2',

  aboutText: '#222222',

  frontDisclaimer: '#c97389',
};

const copaTheme = {
  cellphoneImg: '/img/copa-phone.png',
  followInTheAppPhoneImage: '/img/copa-follow.png',
  logoColor: '#000000',
  startPageText: '#eeeee5',
  cardColor: '#03328D',
  main1: '#130A7E',
  main2: '#03328D',

  btnBg1: '#E33B3B',
  btnBg2: '#03328D',
  btnBg3: '#130A7E',
  btnBg4: '#03328D',

  btnText1: '#ffffff',
  activeText: '#eeeee5',

  redLinePos: '173px',
  headerBg2: '#802239',
  background2: '#F5F5F5',
  spinnerColor: '#130A7E',
  text: '#222222',
  text2: '#222222',
  iconColors: {
    left: '#138b9d',
    right: '#1db4cb',
  },
  hover: {
    cardColor: '#285bdf',
    btnBg1: '#b62f2f',
    btnBg2: '#1c4798',
    btnBg3: '#b62f2f',
    btnBg4: '#1c4798',
  },

  steps: {
    visited: '#989898',
  },
  errorBackground: '#ed3451',
  sponsored2: '#a2a2a2',

  aboutText: '#222222',

  frontDisclaimer: '#c97389',
};

const pl2022Theme = {
  backgroundUrl: ['/img/w-euro-2022-bg@2x.webp', '/img/w-euro-2022-bg@3x.webp'],
  backgroundUrlMobile: ['/img/w-euro-2022-bg@2x.webp', '/img/w-euro-2022-bg@3x.webp'],
  cellphoneImg: null,
  followInTheAppPhoneImage: '/img/more-in-the-app@2x.webp',
  logoColor: '#00985f',
  startPageText: '#ffffff',
  cardColor: '#43266f',
  main1: '#43266f',
  main2: '#43266f',
  btnBg1: '#bd275c',
  btnBg2: '#43266f',
  btnBg3: '#43266f',
  btnBg4: '#43266f',
  btnText1: '#ffffff',
  activeText: '#bd275c',
  redLinePos: '173px',
  headerBg2: '#43266f',
  spinnerColor: '#bd275c',
  text: '#000',
  text2: '#717171',
  iconColors: {
    left: '#138b9d',
    right: '#1db4cb',
  },
  hover: {
    cardColor: '#5e1d79',
    btnBg1: '#c43d6c',
    btnBg2: '#563c7d',
    btnBg4: '#43266f',
  },
  steps: {
    visited: '#7fc8d3',
  },
  errorBackground: '#fc6754',
  sponsored2: '#a2a2a2',

  aboutText: '#000000',

  frontDisclaimer: '#ffffff',
};

const bundesliga2022Theme = {
  backgroundUrl: ['/img/w-euro-2022-bg@2x.webp', '/img/w-euro-2022-bg@3x.webp'],
  backgroundUrlMobile: ['/img/w-euro-2022-bg@2x.webp', '/img/w-euro-2022-bg@3x.webp'],
  cellphoneImg: null,
  followInTheAppPhoneImage: '/img/more-in-the-app@2x.webp',
  logoColor: '#00985f',
  startPageText: '#ffffff',
  cardColor: '#000000',
  main1: '#43266f',
  main2: '#43266f',
  btnBg1: '#bd275c',
  btnBg2: '#43266f',
  btnBg3: '#43266f',
  btnBg4: '#43266f',
  btnText1: '#ffffff',
  activeText: '#bd275c',
  redLinePos: '173px',
  headerBg2: '#43266f',
  spinnerColor: '#bd275c',
  text: '#000',
  text2: '#717171',
  iconColors: {
    left: '#138b9d',
    right: '#1db4cb',
  },
  hover: {
    cardColor: '#333333',
    btnBg1: '#c43d6c',
    btnBg2: '#563c7d',
    btnBg4: '#563c7d',
  },
  steps: {
    visited: '#7fc8d3',
  },
  errorBackground: '#fc6754',
  sponsored2: '#a2a2a2',

  aboutText: '#000000',

  frontDisclaimer: '#ffffff',
};

export function getPredictorTheme(id) {
  if (id?.startsWith('54')) {
    return bundesliga2022Theme;
  }

  if (id?.startsWith('47')) {
    return pl2022Theme;
  }

  if (id?.startsWith('44')) {
    return copaTheme;
  }

  return euroTheme;
}

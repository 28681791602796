import styled, { css } from 'styled-components';
import { colors } from '_constants/cssVars';

export const AllSetContentContainer = styled.div`
  box-sizing: border-box;
  padding-top: 30px;
  background: ${colors.white};
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const AllSetText = styled.p`
  font-size: 14px;
  line-height: 1.57;
  width: 270px;
  text-align: center;
  color: ${colors.text};
  margin: 0 auto;
`;

export const Bold = styled.span`
  font-weight: 500;
`;

const allSkipStyles = css`
  height: 55px;
  width: 300px;
  margin-top: auto;
  font-size: 14px;
  letter-spacing: 0.3px;
  text-align: center;
  color: ${colors.gray};
`;

export const ASSkipLink = styled.a`
  ${allSkipStyles}
`;

export const ASSkipButton = styled.button`
  ${allSkipStyles}
  height: auto;
  margin-top: 10%;
  color:  ${colors.green};
  font-weight: 500;
`;

export const ASHintText = styled(ASSkipButton)`
  cursor: default;
  color: ${colors.text};
`;

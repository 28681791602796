import { useRouter } from 'next/router';
import useEventListener from '@use-it/event-listener';
import { useRedirLocation } from 'components/Predictor/Pages/AllSetPage/LoginComponent/helpers';
import autologin from './autologin';

const useAutologinByMessage = () => {
  const router = useRouter();
  const hasStep = router.query.step ?? false;

  const redirectPath = useRedirLocation(hasStep);

  useEventListener('message', (event) => {
    const {
      provider, user, token,
    } = event.data || {};
    if (!provider || !user || !token) {
      return;
    }

    const { game } = router.query;
    autologin(router.push, {
      game,
      locale: router.locale,
      ...event.data,
      redirectPath: hasStep
        ? redirectPath
        : undefined,
    });
  });
};

export default useAutologinByMessage;

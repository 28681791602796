import React, { useEffect, useRef } from 'react';
import { useSpring } from 'react-spring';
import { BGOverlay } from '_utils/styledComponents';
import { PT } from '_constants/proptypes';
import {
  useLockBodyScroll,
  useOnClickOutside,
  useGetHeight,
} from '_utils/customHooks';
import { useTranslation } from 'react-i18next';
import * as st from './screenInfo.styles';

const PSPScreenInfo = ({ close, isOpen, children }) => {
  const initialModalProps = { transform: 'translateY(1000px)' };
  const initialBgProps = { opacity: 0 };
  const [modalProps, setModalProps] = useSpring(() => initialModalProps);
  const [bgProps, setBgProps] = useSpring(() => initialBgProps);
  useLockBodyScroll(isOpen);
  const [heightRefCb, height] = useGetHeight();
  const ref = useRef(null);
  useOnClickOutside(close, ref);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setModalProps({ transform: 'translateY(0px)' });
      setBgProps({ opacity: 1 });
    } else {
      setModalProps(initialModalProps);
      setBgProps(initialBgProps);
    }
  }, [isOpen]);
  return (
    <>
      <st.PSPScreenInfoWrapper
        style={modalProps}
        ref={(node) => {
          ref.current = node;
          heightRefCb(node);
        }}
        minheight={height}
      >
        <st.PSPScreenInfoContainer>{children}</st.PSPScreenInfoContainer>
        <st.PSICloseButton onClick={close} aria-label={t('close')} />
      </st.PSPScreenInfoWrapper>
      {isOpen && <BGOverlay style={bgProps} />}
    </>
  );
};

PSPScreenInfo.propTypes = {
  close: PT.func.isRequired,
  isOpen: PT.bool.isRequired,
  children: PT.element.isRequired,
};

PSPScreenInfo.defaultProps = {};

export default PSPScreenInfo;

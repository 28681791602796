import { css } from 'styled-components';

export const fontFace = css`
  @font-face {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 300 500;
    font-display: swap;
    src: url("/fonts/Rubik-VariableFont_wght.woff2")
      format("woff2");
  }

  @font-face {
    font-family: "Rubik";
    font-weight: bold;
    src: url("/fonts/Rubik-VariableFont_wght.woff2")
      format("woff2");
  }
`;

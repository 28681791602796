import {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useCookies } from 'react-cookie';

// Hook
export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

export function useRedirect(router, path, condition) {
  useEffect(() => {
    if (condition) {
      router.push(path);
    }
  }, []);
}

export function useResetStepsRedirect(router, arrToCheck, tournamentName) {
  useRedirect(
    router,
    `/${tournamentName}/step/1`,
    arrToCheck.length === 0,
  );
}

export function useSetPathCookie(location) {
  const [, setCookie] = useCookies();
  useEffect(() => {
    setCookie('path', location.pathname);
  }, [location.pathname]);
}

export function useGetHeight() {
  const [height, setHeight] = useState(0);
  const refCb = useCallback((node) => {
    if (node !== null) {
      setHeight(node.offsetHeight);
    }
  });

  return [refCb, height];
}

export function useLockBodyScroll(lock) {
  useEffect(() => {
    // Get original body overflow
    const originalStyle = (typeof window !== 'undefined'
        && window.getComputedStyle(document.body).overflow)
      || 'inherit';

    if (lock) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = originalStyle;
    }
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [lock]); // Empty array ensures effect is only run on mount and unmount
}

export function useOnClickOutside(handler, refArg) {
  const ref = refArg || useRef(null);
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener, { passive: false });

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  );
  return ref;
}

export function useSetParentPositionRelative(show) {
  const nodeRef = useRef(null);
  useEffect(() => {
    const { parentNode } = nodeRef.current;
    const oldPositionValue = parentNode.style.position || 'initial';
    const { height: initialHeight } = parentNode.style;
    if (show) {
      if (parentNode.parentNode && parentNode.parentNode.tagName === 'BODY') {
        parentNode.style.height = '100vh';
      }
      parentNode.style.position = 'relative';
    } else {
      parentNode.style.position = oldPositionValue;
      parentNode.style.height = initialHeight;
    }
    return () => {
      parentNode.style.position = oldPositionValue;
      parentNode.style.height = initialHeight;
    };
  }, []);
  return [nodeRef];
}

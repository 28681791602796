import React from 'react';
import RootContainer from 'components/Predictor/Components/RootContainer';
import styled from 'styled-components';
import * as st from '../../styles/ErrorPage.styles';

const ErrorContainer = styled.section`
  padding: 50px 0;
`;

const FobMot404 = () => (
  <RootContainer>
    <ErrorContainer>
      <st.ErrorHeader>🔎 404: Not Found 👀</st.ErrorHeader>
      <st.ErrorParagraph>
        <p>
          Sorry about that!
        </p>
        <p>
          Please &nbsp;
          <st.ErrorMail href="mailto:support@norapps.no">report the error 📧</st.ErrorMail>
        </p>
        <p>
          or &nbsp;
          <st.ErrorMail href="/">try again 🔁</st.ErrorMail>
        </p>
      </st.ErrorParagraph>
    </ErrorContainer>
  </RootContainer>
);

export default FobMot404;

import React from 'react';
import { PT } from '_constants/proptypes';
import FobMot404 from '../../pages/404';
import { PredictorSpinnerBody, PredictorSpinnerContainer } from './predictorSpinner.styles';

const PredictorSpinner = (props) => {
  const { show, children, render404 } = props;
  const body = render404.renderNotFound && render404.renderNotFound() ? (
    <FobMot404 />
  ) : (
    children
  );
  return show ? (
    <PredictorSpinnerContainer>
      <PredictorSpinnerBody />
    </PredictorSpinnerContainer>
  ) : body;
};

PredictorSpinner.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]),
  show: PT.bool.isRequired,
  render404: PT.shape({
    renderNotFound: PT.func,
  }),
};

PredictorSpinner.defaultProps = {
  children: null,
  render404: {},
};

export default PredictorSpinner;

/* eslint-disable react/no-danger */
import type { TFunction } from 'next-i18next';
import compose from 'lodash/fp/compose';
import { useTranslation as useTranslationReactI18next } from 'react-i18next';
import { sprintf } from 'printj';
import styled from 'styled-components';
import xss from 'xss';

const Container = styled.span`
  b {
    font-weight: 500;
  }
`;

const translate = (i18nTranslate: TFunction) => (key: string, ...rest: any[]): string => {
  const translated = i18nTranslate(key);
  const formatted = sprintf(translated, ...rest);
  return formatted;
};

export const useTranslation = () => {
  const { t: i18nTranslate } = useTranslationReactI18next();

  const t = translate(i18nTranslate);

  return {
    t,
    tRich: (key: string, ...rest: any[]) => compose(
      (value) => (
        <Container dangerouslySetInnerHTML={{ __html: value }} />
      ),
      xss,
      (k, ...r) => t(k, ...r),
    )(key, ...rest),

  };
};

import { ParsedUrlQuery } from 'querystring';

export const getKey = (x: string): string => `prediction:auto:joinleague:${x}`;

const getLocalStorageItem = (key: string): string => {
  try {
    const value = localStorage?.getItem(key);
    return value;
  } catch (error) {
    console.error('Error getting localStorage item', key, error);
    return '';
  }
};

export const removeLocalStorageItem = (key: string): void => {
  try {
    localStorage?.removeItem(key);
  } catch (error) {
    console.error('Error removing localStorage item', key, error);
  }
};

export const setLocalStorageItem = (key: string, value: string): void => {
  try {
    localStorage?.setItem(key, value);
  } catch (error) {
    console.error('Error setting localStorage item', key, value, error);
  }
};

const getQueryLeagueCode = ({ code, joinleague }: ParsedUrlQuery): string => {
  if (code) {
    return String(code);
  }

  if (joinleague) {
    return String(joinleague);
  }

  return undefined;
};

const getStoredLeagueCode = (game: string): string => getLocalStorageItem(getKey(game));

export const getLeagueCode = (query: ParsedUrlQuery): string => {
  const { game } = query;
  const q = getQueryLeagueCode(query);
  const s = getStoredLeagueCode(String(game));

  return q ?? s;
};

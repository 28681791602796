import React from 'react';

export const LocationProvider = React.createContext();
export const IsDesktopProvider = React.createContext();
export const PredictorUserContext = React.createContext();
export const HeaderHeightProvider = React.createContext();
export const CombinedContextProvider = React.createContext();
export const LogoutModalContext = React.createContext();

export default LocationProvider;

export const mediaSizes = {
  desktop: '821px',
  mobile: '400px',
  tablet: '768px',
};

export const widths = {
  maxWidth: '1440px',
  predictorContentMaxWidth: '570px',
  maxContentWidth: '798px',
  maxTabletContentWidth: '680px',
  maxMobileContentWidth: '320px',
};

export const heights = {
  minHeight: '480px',
};

export const mediaQueries = {
  desktop: `(min-width: ${mediaSizes.desktop})`,
  tablet: `(min-width: ${mediaSizes.tablet})`,
  // mobile: `(max-width: ${mediaSizes.mobile})`,
  mobile: `(max-width: ${widths.predictorContentMaxWidth})`,
  hugeDesktop: `(min-width: ${widths.maxWidth})`,
  minHeight: `(max-height: ${heights.minHeight})`,
  ios: '(-webkit-overflow-scrolling: touch)',
};

export const colors = {
  white: '#fff',
  green: '#00985F',
  bluishWhite: '#f9f9fa',
  mostlyWhite: '#fafafa',
  text: '#333333',
  darkGray: '#989898',
  whiteSmoke: '#f5f5f5',
  lightViolet: '#4a4790',
  violet: '#2f2371',
  violet2: '#291e68',
  almostAmber: '#ffb100',
  persianWhite: '#e7e6ec',
  lightYellow: '#feff59',
  lightGray: '#f4f3f8',
  text2: '#555555',
  gray: '#808080',
  cobaltBlue: '#4267b2',
  veryLightGray: '#eeeeee',
  veryLightGray2: '#f0f0f0',
  luminousBlue: '#00aced',
  lightRed: '#fc5454',
  lightRed2: '#e55e5b',
  black: 'black',
  almostWhite: '#fdfdfd',
  lightGray2: '#ebebeb',
  red: '#e84343',
  lightGrayishBlue: '#f2f4f7',
  darkGray2: '#838383',
  gray2: '#acacac',
  borderColor1: '#ececec',
};

export const fsizes = {
  large: '18px',
  regular: '14px',
  small: '12px',
  xsmall: '11px',
};

export const boxShadow = {
  default: '0 3px 11px 0 rgba(0, 0, 0, 0.05)',
};

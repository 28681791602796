import PT, { instanceOf } from 'prop-types';

export const livescoreGroupPT = PT.shape({
  ccode: PT.arrayOf(PT.string),
  id: PT.arrayOf(PT.oneOfType([PT.string, PT.number])),
  ir: PT.arrayOf(PT.oneOfType([PT.string, PT.number])),
  lr: PT.arrayOf(PT.oneOfType([PT.string, PT.number])),
  match: PT.arrayOf(
    PT.shape({
      aId: PT.arrayOf(PT.string),
      aScore: PT.arrayOf(PT.string),
      aTeam: PT.arrayOf(PT.string),
      extid: PT.arrayOf(PT.string),
      hId: PT.arrayOf(PT.string),
      hScore: PT.arrayOf(PT.string),
      hTeam: PT.arrayOf(PT.string),
      id: PT.arrayOf(PT.string),
      sId: PT.arrayOf(PT.string),
      stage: PT.arrayOf(PT.string),
      status: PT.arrayOf(PT.string),
      time: PT.arrayOf(PT.string),
      tvSchedule: PT.arrayOf(PT.any),
    }),
  ),
  name: PT.arrayOf(PT.string),
  pl: PT.arrayOf(PT.oneOfType([PT.string, PT.number])),
  sl: PT.arrayOf(PT.oneOfType([PT.string, PT.number])),
});

const playerInShape = PT.shape({
  id: PT.string,
  name: PT.string,
});

export const leagueTablePT = PT.shape({
  table: PT.shape({
    ccode: PT.arrayOf(PT.string),
    l_cl: PT.arrayOf(PT.string),
    l_relegation: PT.arrayOf(PT.string),
    l_uefa: PT.arrayOf(PT.string),
    lid: PT.arrayOf(PT.string),
    name: PT.arrayOf(PT.string),
    qp: PT.arrayOf(PT.string),
    rp: PT.arrayOf(PT.string),
    t: PT.arrayOf(
      PT.shape({
        c: PT.arrayOf(PT.string),
        change: PT.arrayOf(PT.string),
        d: PT.arrayOf(PT.string),
        g: PT.arrayOf(PT.string),
        hc: PT.arrayOf(PT.string),
        hd: PT.arrayOf(PT.string),
        hg: PT.arrayOf(PT.string),
        hl: PT.arrayOf(PT.string),
        hp: PT.arrayOf(PT.string),
        hw: PT.arrayOf(PT.string),
        id: PT.arrayOf(PT.string),
        l: PT.arrayOf(PT.string),
        name: PT.arrayOf(PT.string),
        p: PT.arrayOf(PT.string),
        w: PT.arrayOf(PT.string),
      }),
    ),
    tp: PT.arrayOf(PT.string),
    wp: PT.arrayOf(PT.string),
  }),
});

export const matchEventsPT = PT.arrayOf(
  PT.shape({
    eventType: PT.string,
    homeEvent: PT.bool,
    playerIn: playerInShape,
    playerOut: playerInShape,
    time: PT.oneOfType([PT.string, PT.number]),
  }),
);

export const liveTickerPT = PT.shape({
  AutoCommentaries: PT.bool,
  AwayteamId: PT.number,
  Events: PT.arrayOf(
    PT.shape({
      Description: PT.string,
      Elapsed: PT.number,
      ElapsedPlus: PT.number,
      IncidentCode: PT.string,
    }),
  ),
  HometeamId: PT.number,
  Lang: PT.string,
});

export const livescorePT = PT.shape({
  awayPenScore: PT.oneOfType([PT.string, PT.number]),
  awayScore: PT.number,
  awayTeam: PT.shape({}),
  coaches: PT.shape({}),
  fehs: PT.string,
  finished: PT.string,
  form_at: PT.shape({}),
  form_ht: PT.shape({}),
  funFacts: PT.shape({}),
  gs: PT.string,
  hasNews: PT.bool,
  headToHead: PT.shape({}),
  homePenScore: PT.oneOfType([PT.string, PT.number]),
  homeScore: PT.number,
  homeTeam: PT.shape({}),
  id: PT.oneOfType([PT.string, PT.arrayOf(PT.string)]),
  isCup: PT.bool,
  leagueId: PT.string,
  leagueName: PT.string,
  ltc: PT.string,
  matchDate: PT.string,
  matchEvents: PT.arrayOf(PT.shape({})),
  matchStats: PT.shape({}),
  parentLeagueId: PT.string,
  postponed: PT.string,
  referee: PT.shape({}),
  sId: PT.arrayOf(PT.string),
  sehs: PT.string,
  shs: PT.string,
  simpleMatchStats: PT.shape({}),
  stage: PT.string,
  started: PT.string,
  substitutions: PT.arrayOf(PT.shape({})),
  superLiveUrl: PT.string,
  twitterFeed: PT.string,
  venue: PT.string,
});

export const leaguePT = PT.shape({
  id: PT.arrayOf(PT.oneOfType([PT.string, PT.number])),
  lccode: PT.arrayOf(PT.string),
  name: PT.arrayOf(PT.string),
  sortLevel: PT.arrayOf(PT.string),
  sortLevelInternal: PT.arrayOf(PT.string),
});

export const teamTablePT = PT.shape({
  c: PT.arrayOf(PT.string),
  change: PT.arrayOf(PT.string),
  d: PT.arrayOf(PT.string),
  g: PT.arrayOf(PT.string),
  hc: PT.arrayOf(PT.string),
  hd: PT.arrayOf(PT.string),
  hg: PT.arrayOf(PT.string),
  hl: PT.arrayOf(PT.string),
  hp: PT.arrayOf(PT.string),
  hw: PT.arrayOf(PT.string),
  id: PT.arrayOf(PT.string),
  l: PT.arrayOf(PT.string),
  name: PT.arrayOf(PT.string),
  p: PT.arrayOf(PT.string),
  w: PT.arrayOf(PT.string),
});

export const tablePT = PT.shape({
  ccode: PT.arrayOf(PT.string),
  l_cl: PT.arrayOf(PT.string),
  l_relegation: PT.arrayOf(PT.string),
  l_uefa: PT.arrayOf(PT.string),
  lid: PT.arrayOf(PT.string),
  name: PT.arrayOf(PT.string),
  qp: PT.arrayOf(PT.string),
  rp: PT.arrayOf(PT.string),
  t: PT.arrayOf(teamTablePT),
  tp: PT.arrayOf(PT.string),
  wp: PT.arrayOf(PT.string),
});

export const positionIndicatorsPT = PT.shape({
  championsLeague: PT.shape({}),
  championshipPlayoff: PT.shape({}),
  clQualification: PT.shape({}),
  copaLibertadores: PT.shape({}),
  copaLibertadoresQualification: PT.shape({}),
  copaSudamericana: PT.shape({}),
  elQualification: PT.shape({}),
  europaLeague: PT.shape({}),
  playoffs: PT.shape({}),
  possibleQualificationNextStage: PT.shape({}),
  promotion: PT.shape({}),
  qualificationNextStage: PT.shape({}),
  relegation: PT.shape({}),
  relegationPlayoff: PT.shape({}),
});

export const groupPT = PT.shape({
  ccode: PT.arrayOf(PT.string),
  cname: PT.arrayOf(PT.string),
  league: PT.arrayOf(leaguePT),
});

export const leaguesPT = PT.shape({
  group: PT.arrayOf(groupPT),
  selected: PT.arrayOf(
    PT.shape({
      league: PT.arrayOf(leaguePT),
    }),
  ),
});

export const locationPT = PT.shape({
  pathname: PT.string.isRequired,
});

export const routerPT = PT.shape({
  push: PT.func.isRequired,
});

export const matchesPT = PT.shape({
  fetching: PT.bool,
  ads: PT.arrayOf(PT.string),
  league: PT.arrayOf(leaguePT),
  msg3: PT.arrayOf(PT.string),
  msgId: PT.arrayOf(PT.string),
});

export const matchPT = PT.shape({
  aId: PT.string,
  awayScore: PT.string,
  awayTeam: PT.string,
  hId: PT.string,
  homeScore: PT.string,
  homeTeam: PT.string,
  id: PT.string,
  lname: PT.string,
  matchDateEx: PT.string,
  statusOfMatch: PT.string,
});

export const playerPT = PT.shape({
  age: PT.string,
  assists: PT.string,
  averageRating: PT.string,
  ccode: PT.string,
  cname: PT.string,
  goals: PT.string,
  id: PT.string,
  name: PT.string,
  'number of ratings': PT.string,
  penalties: PT.string,
  position: PT.string,
  positionDesc: PT.string,
  rcards: PT.string,
  shirtNo: PT.string,
  ycards: PT.string,
});

export const teamDataPT = PT.shape({
  CountryCode: PT.string,
  LeagueRank: PT.string,
  PrimaryTournamentTemplate: PT.string,
  Squad: PT.arrayOf(PT.arrayOf(playerPT)),
  matches: PT.arrayOf(matchPT),
  newFeeds: PT.arrayOf(
    PT.shape({
      feed: PT.arrayOf(
        PT.shape({
          $: PT.shape({
            format: PT.string,
            lang: PT.string,
            url: PT.string,
          }),
        }),
      ),
    }),
  ),
  size: PT.number,
  theTeam: PT.shape({
    id: PT.number,
    name: PT.string,
    notifications: PT.arrayOf(PT.string),
  }),
  trophy: PT.arrayOf(
    PT.shape({
      comp: PT.arrayOf(
        PT.shape({
          $: PT.shape({
            name: PT.string,
            tournamentTemplateId: PT.string,
            area: PT.string,
            ccode: PT.string,
            won: PT.string,
            runnerup: PT.string,
          }),
          'season runnerup': PT.arrayOf(PT.string),
          'season won': PT.arrayOf(PT.string),
        }),
      ),
    }),
  ),
  map: PT.shape({}),
});

export const cookiesPT = PT.shape({
  changeListeners: PT.arrayOf(PT.func.isRequired),
  TESTING_ONETWO: PT.number.isRequired,
  cookies: PT.shape({
    user: PT.string,
    guser: PT.string,
    tuser: PT.string,
    fbuser: PT.string,
    path: PT.string,
  }),
  HAS_DOCUMENT_COOKIE: PT.bool.isRequired,
});

export const predictorTeamPT = PT.shape({
  name: PT.string.isRequired,
  id: PT.number.isRequired,
  imageUri: PT.string.isRequired,
  countryCode: PT.string.isRequired,
});

export const predictorGroupPT = PT.shape({
  name: PT.string.isRequired,
  stageId: PT.string,
  teams: PT.arrayOf(predictorTeamPT).isRequired,
});

export const predictorGroupsPT = PT.arrayOf(predictorGroupPT);

const ABCDShape = PT.shape({
  '3ABF': PT.string.isRequired,
  '3ACD': PT.string.isRequired,
  '3BEF': PT.string.isRequired,
  '3CDE': PT.string.isRequired,
});

export const tournamentConfigPT = PT.shape({
  firstGame: PT.string,
  firstKnockoutRound: PT.arrayOf(
    PT.shape({
      away: PT.string.isRequired,
      home: PT.string.isRequired,
    }).isRequired,
  ),
  groupAdvance3rdPlace: PT.shape({
    ABCD: ABCDShape,
    ABCE: ABCDShape,
    ABCF: ABCDShape,
    ABDE: ABCDShape,
    ABDF: ABCDShape,
    ABEF: ABCDShape,
    ACDE: ABCDShape,
    ACDF: ABCDShape,
    ACEF: ABCDShape,
    ADEF: ABCDShape,
    BCDE: ABCDShape,
    BCDF: ABCDShape,
    BCEF: ABCDShape,
    BDEF: ABCDShape,
    CDEF: ABCDShape,
  }),
  groupCount: PT.number,
  groups: PT.arrayOf(predictorGroupPT),
  name: PT.string,
  selectTopScorer: PT.bool,
  teamsInGroup: PT.number,
  thirdPlaceAdvanceCount: PT.number,
  tournamentId: PT.number,
  noBronzeFinal: PT.bool,
});

export const ro16PredictionPT = PT.shape({
  home: PT.shape({
    title: PT.string.isRequired,
    team: predictorTeamPT.isRequired,
  }).isRequired,
  away: PT.shape({
    title: PT.string.isRequired,
    team: predictorTeamPT.isRequired,
  }).isRequired,
  winner: PT.string,
});

export const predictorLuckyLosersPT = PT.arrayOf(
  PT.shape({
    name: PT.string.isRequired,
    id: PT.number.isRequired,
    imageUri: PT.string.isRequired,
    countryCode: PT.string.isRequired,
    fromGroup: PT.string.isRequired,
  }),
);

export const predictorValidationErrorPT = PT.shape({
  showError: PT.bool.isRequired,
  text: PT.string,
});

const basePTforCompletedPrediction = PT.shape({
  prediction: PT.arrayOf(
    PT.shape({
      id: PT.number.isRequired,
    }),
  ),
});

export const userPT = PT.shape({
  displayName: PT.string.isRequired,
  email: PT.string.isRequired,
  id: PT.string.isRequired,
  image: PT.string.isRequired,
  loggedIn: PT.bool.isRequired,
  name: PT.string.isRequired,
  provider: PT.string.isRequired,
});

export const userPredictionsPT = PT.shape({
  groupStage: predictorGroupsPT.isRequired,
  luckyLosers: predictorGroupPT,
  roundOf16: PT.arrayOf(ro16PredictionPT),
  quarterfinals: PT.arrayOf(ro16PredictionPT),
  semifinals: PT.arrayOf(ro16PredictionPT),
  finals: PT.arrayOf(ro16PredictionPT),
  error: PT.shape({
    showError: PT.bool.isRequired,
  }),
});

export const userCompletedPredictionsPT = PT.shape({
  user: userPT,
  groups: PT.arrayOf(basePTforCompletedPrediction),
  Best3rdThatAdvancesToKnockout: PT.arrayOf(basePTforCompletedPrediction),
  roundOf16Winners: PT.arrayOf(basePTforCompletedPrediction),
  quarterFinalWinners: PT.arrayOf(basePTforCompletedPrediction),
  semiFinalWinners: PT.arrayOf(basePTforCompletedPrediction),
  bronzeWinner: PT.shape({
    id: PT.number.isRequired,
  }),
  winner: PT.shape({
    id: PT.number.isRequired,
  }),
});

export const predictorLeaguePT = PT.shape({
  Id: PT.number,
  Name: PT.string,
  OwnerId: PT.string,
  Registered: PT.string,
});

export const predictorLeaguesPT = PT.arrayOf(predictorLeaguePT);

export const routeParamsPT = PT.shape({});

export { PT, instanceOf };

import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { User } from 'lib/useUser';
import { useDispatch } from 'react-redux';
import { leaguesSlice } from '_store/reducers';
import {
  getKey, getLeagueCode, setLocalStorageItem, removeLocalStorageItem,
} from './helpers';
import { LeagueActions } from './types';

const { joinLeague } = leaguesSlice.actions as LeagueActions;

const useJoinLeague = (user: User) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { game } = router.query;
  const key = getKey(String(game));

  useEffect(() => {
    const leagueCode = getLeagueCode(router.query);
    if (!leagueCode) {
      return;
    }

    if (user.loggedIn) {
      removeLocalStorageItem(key);
      dispatch(joinLeague({
        leagueCode,
        tournamentId: user.tournamentId,
      }));
    } else {
      setLocalStorageItem(key, leagueCode);
    }
  }, [user.loggedIn, game]);
};

export default useJoinLeague;

export const hasLuckyLoserStage = (config) => {
  const { thirdPlaceAdvanceCount } = config || {};
  return thirdPlaceAdvanceCount > 0;
};

export const hasRoundOf16Stage = (config) => {
  const { firstKnockoutRound } = config || {};
  const matchCount = firstKnockoutRound?.length ?? 0;
  return matchCount > 4;
};

export const hasBronzeFinal = (config) => {
  const { noBronzeFinal = true } = config || {};

  return !noBronzeFinal;
};

import { combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';

import {
  userAgentSlice,
  languageSlice,
  translationsSlice,
  oddsSlice,
  predictedWinnerSlice,
  locationSlice,
  tournamentSlice as tournament,
  stepsSlice,
  userPredictionSlice,
  userWheelSlice,
  userCompletedPredictionsSlice,
  leaguesSlice,
  predictorRedirectSlice,
  counterSlice as counter,
  configSlice,
} from './tournamentReducers';

const hydrateReducer = (state = {}, { type, payload } = {}) => {
  switch (type) {
    case HYDRATE: {
      return {
        ...payload,
      };
    }
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  server: hydrateReducer,
  userAgent: userAgentSlice.reducer,
  language: languageSlice.reducer,
  translations: translationsSlice.reducer,
  odds: oddsSlice.reducer,
  predictedWinner: predictedWinnerSlice.reducer,
  location: locationSlice.reducer,
  tournament: tournament.reducer,
  predictorSteps: stepsSlice.reducer,
  userPrediction: userPredictionSlice.reducer,
  userWheel: userWheelSlice.reducer,
  userCompletedPredictions: userCompletedPredictionsSlice.reducer,
  predictorLeagues: leaguesSlice.reducer,
  predictorRedirect: predictorRedirectSlice.reducer,
  config: configSlice.reducer,
  counter: counter.reducer,
});

export {
  tournament,
  userAgentSlice,
  languageSlice,
  translationsSlice,
  oddsSlice,
  predictedWinnerSlice,
  locationSlice,
  stepsSlice,
  userPredictionSlice,
  userWheelSlice,
  userCompletedPredictionsSlice,
  leaguesSlice,
  predictorRedirectSlice,
  configSlice,
  counter,
};

export default rootReducer;

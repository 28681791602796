import Cookies from 'universal-cookie';

const USER_COOKIE_NAME = 'user';
const FB_USER_COOKIE_NAME = 'fbuser';
const GOOGLE_USER_COOKIE_NAME = 'guser';
const APPLE_USER_COOKIE_NAME = 'auser';

function clearPreviousLogins() {
  // TODO: refactor cookies, remove universal-cookie package, move to react-cookie
  const cookies = new Cookies();
  if (cookies.get(USER_COOKIE_NAME)) {
    cookies.remove(USER_COOKIE_NAME, { path: '/' });
  }
  if (cookies.get(FB_USER_COOKIE_NAME)) {
    cookies.remove(FB_USER_COOKIE_NAME, { path: '/' });
  }
  if (cookies.get(GOOGLE_USER_COOKIE_NAME)) {
    cookies.remove(GOOGLE_USER_COOKIE_NAME, { path: '/' });
  }
  if (cookies.get(APPLE_USER_COOKIE_NAME)) {
    cookies.remove(APPLE_USER_COOKIE_NAME, { path: '/' });
  }

  if (localStorage) {
    localStorage.removeItem('cognitoSync');
    localStorage.removeItem('cognitoProfile');
  }

  // Turn off notifications
  const { OneSignal } = window;
  if (OneSignal && localStorage.getItem('notificationsEnabled')) {
    OneSignal.setSubscription(false);
  }
}

function loginFB(redirectPath) {
  clearPreviousLogins();
  window.location.href = `/api/auth/facebook?redirectPath=${redirectPath}`;
}

function loginGoogle(redirectPath) {
  clearPreviousLogins();
  window.location.href = `/api/auth/google?redirectPath=${redirectPath}`;
}

function loginApple(redirectPath) {
  clearPreviousLogins();
  window.location.href = `/api/auth/apple?redirectPath=${redirectPath}`;
}

export const LOGIN_PROVIDERS = {
  FB: 'facebook',
  GOOGLE: 'google',
  APPLE: 'apple',
};

export function login(provider, redirectPath = '/') {
  switch (provider) {
    case LOGIN_PROVIDERS.FB:
      loginFB(redirectPath);
      break;
    case LOGIN_PROVIDERS.GOOGLE:
      loginGoogle(redirectPath);
      break;
    case LOGIN_PROVIDERS.APPLE:
      loginApple(redirectPath);
      break;
    default:
      break;
  }
}

export function isLoggedIn() {
  const cookies = new Cookies();
  return Boolean(
    cookies.get(FB_USER_COOKIE_NAME)
      || cookies.get(GOOGLE_USER_COOKIE_NAME)
      || cookies.get(APPLE_USER_COOKIE_NAME),
  );
}

function reportToGoogleAnalytics() {
  // window.ga("set", "dimension1", true);
  // window.ga("send", "pageview");
}

export function getUser() {
  const cookies = new Cookies();
  const user = cookies.get('user');
  const fbUser = user && cookies.get('fbuser');
  const gUser = user && cookies.get('guser');
  const aUser = user && cookies.get('auser');
  const displayName = 'PROFILE';
  if (fbUser) {
    reportToGoogleAnalytics();
    const { image = '', name, id } = fbUser;
    return {
      provider: 'facebook',
      id: `1_${id}`,
      name,
      displayName,
      image,
      loggedIn: true,
      token: user,
    };
  }
  if (gUser) {
    reportToGoogleAnalytics();
    const {
      image = '', name, email, id,
    } = gUser;
    return {
      provider: 'google',
      id: `2_${id}`,
      name,
      displayName,
      email,
      image,
      loggedIn: true,
      gUser,
      token: user,
    };
  }
  if (aUser) {
    reportToGoogleAnalytics();
    const { image = '', name, id } = aUser;
    return {
      provider: 'apple',
      id: `4_${id}`,
      name,
      displayName,
      image,
      loggedIn: true,
      token: user,
    };
  }
  return {
    displayName: undefined,
    image: '',
    loggedIn: false,
  };
}

export function getCognitoProfile() {
  if (typeof window === 'undefined') {
    return {};
  }
  const item = window.localStorage.getItem('cognitoProfile');
  if (!item) {
    return {};
  }
  return JSON.parse(item);
}

import {
  put, call, take, delay,
} from 'redux-saga/effects';
import { END } from 'redux-saga';
import {
  tournament,
  locationSlice,
  translationsSlice,
  oddsSlice,
  predictedWinnerSlice,
  userWheelSlice,
  userCompletedPredictionsSlice,
  leaguesSlice,
  predictorRedirectSlice,
} from '../reducers/index';

import {
  getBackendPredictionStructure,
} from '../../components/Predictor/Pages/helpers/index';
import {
  getTranslations,
  getOdds,
  getPredictedWinner,
  getLocation,
  getUserPredictionsReq,
  getTournamentConfig,
  getWheelUrlReq,
  saveUserPredictionsReq,
  createLeagueReq,
  predictorGetUserReq,
  getSingleLeagueReq,
  leaveLeagueReq,
  joinLeagueReq,
} from '../remoteCalls/index';

const CLEAN_REDIRECT_ACTION_DELAY = 2000;

export function* tournamentTaskClient() {
  while (true) {
    try {
      const action = yield take(tournament.actions.fetch);
      const payload = yield call(getTournamentConfig, action.payload);
      yield put(tournament.actions.success(payload));
    } catch (err) {
      const { message = 'something went wrong in tournamentTask' } = err;
      yield put(tournament.actions.failure(message));
    }
  }
}

export function* locationTaskClient() {
  while (true) {
    try {
      const action = yield take(locationSlice.actions.fetch);
      const payload = yield call(getLocation, action.payload);
      yield put(locationSlice.actions.success(payload));
    } catch (err) {
      const { message = 'something went wrong in locationTaskClient' } = err;
      yield put(locationSlice.actions.failure(message));
    }
  }
}

export function* translationsTaskClient() {
  while (true) {
    try {
      const action = yield take(translationsSlice.actions.fetch);
      const payload = yield call(getTranslations, action.payload);
      yield put(translationsSlice.actions.success(payload));
    } catch (err) {
      const { message = 'something went wrong in translationsTaskClient' } = err;
      yield put(translationsSlice.actions.failure(message));
    }
  }
}

export function* oddsTaskClient() {
  while (true) {
    try {
      const action = yield take(oddsSlice.actions.fetch);
      const payload = yield call(getOdds, action.payload || {});
      yield put(oddsSlice.actions.success(payload));
    } catch (err) {
      const { message = 'something went wrong in oddsTaskClient' } = err;
      yield put(oddsSlice.actions.failure(message));
    }
  }
}

export function* translationsTaskBacken() {
  // because `take` can never fail
  // eslint-disable-next-line redux-saga/no-unhandled-errors
  let action = yield take(translationsSlice.actions.fetch);
  while (action !== END) {
    try {
      if (!action) {
        action = yield take(translationsSlice.actions.fetch);
      }
      const payload = yield call(getTranslations, action.payload);
      action = null;
      yield put(translationsSlice.actions.success(payload));
    } catch (err) {
      const { message = 'something went wrong in translationsTaskBacken' } = err;
      yield put(translationsSlice.actions.failure(message));
    }
  }
}

export function* tournamentTaskBackend() {
  // because `take` can never fail
  // eslint-disable-next-line redux-saga/no-unhandled-errors
  let action = yield take(tournament.actions.fetch);
  while (action !== END) {
    try {
      if (!action) {
        action = yield take(tournament.actions.fetch);
      }
      const payload = yield call(getTournamentConfig, action.payload);
      action = null;
      yield put(tournament.actions.success(payload));
    } catch (err) {
      const { message = 'something went wrong in tournamentTask' } = err;
      yield put(tournament.actions.failure(message));
    }
  }
}

export function* oddsTaskBackend() {
  let action = yield take(oddsSlice.actions.fetch);
  while (action !== END) {
    try {
      if (!action) {
        action = yield take(oddsSlice.actions.fetch);
      }
      const payload = yield call(getOdds, action.payload || {});
      action = null;
      yield put(oddsSlice.actions.success(payload));
    } catch (err) {
      const { message = 'something went wrong in oddsTaskBackend' } = err;
      yield put(oddsSlice.actions.failure(message));
    }
  }
}

export function* predictedWinnerTaskBackend() {
  let action = yield take(predictedWinnerSlice.actions.fetch);
  while (action !== END) {
    try {
      if (!action) {
        action = yield take(predictedWinnerSlice.actions.fetch);
      }
      const payload = yield call(getPredictedWinner, action.payload || {});
      action = null;
      yield put(predictedWinnerSlice.actions.success(payload));
    } catch (err) {
      const { message = 'something went wrong in predictedWinnerTaskBackend' } = err;
      yield put(predictedWinnerSlice.actions.failure(message));
    }
  }
}

export function* getWheelUrl() {
  while (true) {
    try {
      const action = yield take(userWheelSlice.actions.fetchWheel);
      const payload = yield call(getWheelUrlReq, action.payload);
      yield put(userWheelSlice.actions.fetchWheelSuccess(payload));
    } catch (err) {
      const { message = 'something went wrong in getWheelUrl Task' } = err;
      yield put(userWheelSlice.actions.fetchWheelFailure(message));
    }
  }
}

export function* saveUserPredictions() {
  while (true) {
    try {
      const action = yield take(
        userCompletedPredictionsSlice.actions.savePredictions,
      );

      const prediction = getBackendPredictionStructure(
        action.payload.user,
        action.payload.predictions,
        action.payload.tournamentConfig,
      );

      yield put(userWheelSlice.actions.fetchWheel(prediction));

      const payload = yield call(
        saveUserPredictionsReq,
        prediction,
      );

      yield put(
        userCompletedPredictionsSlice.actions.savePredictionsSuccess(payload),
      );
      yield put(
        userCompletedPredictionsSlice.actions.getPredictions(action.payload.user),
      );
    } catch (err) {
      const {
        message = 'something went wrong in saveUserPredictions Task',
        status = 500,
      } = err;
      yield put(
        userCompletedPredictionsSlice.actions.savePredictionsFailure({ status, message }),
      );
    }
  }
}

export function* getUserPredictions() {
  while (true) {
    try {
      const action = yield take(
        userCompletedPredictionsSlice.actions.getPredictions,
      );
      const payload = yield call(getUserPredictionsReq, action.payload);
      yield put(
        userCompletedPredictionsSlice.actions.getPredictionsSuccess(payload),
      );
    } catch (err) {
      const {
        message = 'something went wrong in getUserPredictions Task',
      } = err;

      yield put(
        userCompletedPredictionsSlice.actions.getPredictionsFailure(message),
      );
    }
  }
}

export function* createLeague() {
  while (true) {
    try {
      const action = yield take(leaguesSlice.actions.createLeague);
      const createLeagueReqPayload = yield call(
        createLeagueReq,
        action.payload,
      );
      yield put(
        leaguesSlice.actions.createLeagueSuccess({
          ...createLeagueReqPayload,
          tournamentId: action.payload.tournamentId,
        }),
      );
      yield put(leaguesSlice.actions.getLeagues(action.payload));

      yield delay(CLEAN_REDIRECT_ACTION_DELAY);
      yield put(predictorRedirectSlice.actions.clearRedirect());
    } catch (err) {
      const { message = 'something went wrong in createLeague Task' } = err;
      yield put(leaguesSlice.actions.createLeagueFailure(message));
    }
  }
}

export function* predictorGetUser() {
  while (true) {
    try {
      const action = yield take(leaguesSlice.actions.getLeagues);
      const payload = yield call(predictorGetUserReq, action.payload);
      yield put(leaguesSlice.actions.getLeaguesSuccess(payload));
    } catch (err) {
      const { message = 'something went wrong in predictorGetUser Task' } = err;
      yield put(leaguesSlice.actions.getLeaguesFailure(message));
    }
  }
}

export function* getSingleLeagueSaga() {
  while (true) {
    const action = yield take(leaguesSlice.actions.getSingleLeague);
    try {
      const getSingleLeagueReqPayload = yield call(
        getSingleLeagueReq,
        action.payload,
      );
      yield put(
        leaguesSlice.actions.getSingleLeagueSuccess(getSingleLeagueReqPayload),
      );
    } catch (err) {
      const { message = 'something went wrong in getSingleLeague Task' } = err;
      yield put(leaguesSlice.actions.getSingleLeagueFailure({
        ...action.payload,
        message,
      }));

      yield delay(CLEAN_REDIRECT_ACTION_DELAY);
      yield put(predictorRedirectSlice.actions.clearRedirect());
    }
  }
}

export function* leaveLeagueSaga() {
  while (true) {
    try {
      const action = yield take(leaguesSlice.actions.leaveLeague);
      const leaveLeagueReqPayload = yield call(leaveLeagueReq, action.payload);
      yield put(
        leaguesSlice.actions.leaveLeagueSuccess({
          response: leaveLeagueReqPayload,
          tournamentId: action.payload.tournamentId,
          tournamentName: leaveLeagueReqPayload.tournamentName,
        }),
      );
      yield put(leaguesSlice.actions.getLeagues(action.payload));

      yield delay(CLEAN_REDIRECT_ACTION_DELAY);
      yield put(predictorRedirectSlice.actions.clearRedirect());
    } catch (err) {
      const { message = 'something went wrong in leaveLeague Task' } = err;
      yield put(leaguesSlice.actions.leaveLeagueFailure(message));
    }
  }
}

export function* joinLeagueSaga() {
  while (true) {
    try {
      const action = yield take(leaguesSlice.actions.joinLeague);
      const joinLeagueReqPayload = yield call(joinLeagueReq, action.payload);
      yield put(
        leaguesSlice.actions.joinLeagueSuccess({
          ...joinLeagueReqPayload,
          tournamentId: action.payload.tournamentId,
        }),
      );
      yield put(leaguesSlice.actions.getLeagues(action.payload));

      yield delay(CLEAN_REDIRECT_ACTION_DELAY);
      yield put(predictorRedirectSlice.actions.clearRedirect());
    } catch (err) {
      const { message = 'something went wrong in joinLeague Task' } = err;
      yield put(leaguesSlice.actions.joinLeagueFailure(message));
    }
  }
}

import { getPredictorTheme } from 'components/Predictor/Components/PredictorContainer/predictorTheme';
import { useMemo } from 'react';
import { useTournamentId } from './useTournamentId';

export const useResolvePredictorTheme = (id?: string) => {
  const tournamentId = useTournamentId();
  const x = tournamentId || id;

  const theme = useMemo(() => getPredictorTheme(x), [x]);
  return theme;
};

import { ParsedUrlQuery } from 'querystring';

type QueryWithUserId = {
  userId?: string;
}

const KEY = 'app-user-id';
const setItem = (key: string, value: string): void => {
  if (typeof window !== 'undefined') {
    try {
      // Save to local storage
      window.localStorage.setItem(key, value);
    } catch (error) {
      console.error('Error setting localStorage item', key, value, error);
    }
  }
};

const getItem = (key: string): string => {
  if (typeof window !== 'undefined') {
    try {
      // Get from local storage by key
      return window.localStorage.getItem(key);
    } catch (error) {
      console.error('Error getting localStorage item', key, error);
    }
  }
  return undefined;
};

export const storeReadOnlyUserId = (userId: string): void => {
  setItem(KEY, userId);
};

export const readReadOnlyUserId = (): string => getItem(KEY);

export const getUserIdFromQuery = (query: ParsedUrlQuery) => {
  const { userId } = query as QueryWithUserId;
  return userId;
};

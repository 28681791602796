import Cookies from 'universal-cookie';
import { PROFILE_DEFAULT_IMAGE_PATH } from '_constants/imageConstants';
import {
  LOGIN_PROVIDERS as ls,
  USER_COOKIE_NAME,
  FB_USER_COOKIE_NAME,
  GOOGLE_USER_COOKIE_NAME,
  APPLE_USER_COOKIE_NAME,
} from './loginProviders';

export const LOGIN_PROVIDERS = ls;

const prodiverCookieNames = [
  FB_USER_COOKIE_NAME,
  GOOGLE_USER_COOKIE_NAME,
  APPLE_USER_COOKIE_NAME,
];

const userCookieNames = [
  USER_COOKIE_NAME,
  ...prodiverCookieNames,
];

export function clearPreviousLogins() {
  const cookies = new Cookies();
  userCookieNames
    .filter((cookieName) => cookies.get(cookieName))
    .forEach((cookieName) => {
      cookies.remove(cookieName, { path: '/' });
    });
}

const getBasePath = () => {
  const origin = typeof window !== 'undefined' && window.location.origin
    ? window.location.origin
    : 'https://predict.fotmob.com';

  return origin;
};

export const login = (provider, redirectPath = '/') => {
  const basePath = getBasePath();
  clearPreviousLogins();

  window.location.href = `${basePath}/api/auth/${provider}?redirectPath=${redirectPath}`;
};

export function functionisLoggedIn() {
  const cookies = new Cookies();
  return prodiverCookieNames
    .map((cookieName) => cookies.get(cookieName))
    .map(Boolean)
    .some((x) => x);
}

function reportToGoogleAnalytics() {
  // window.ga("set", "dimension1", true);
  // window.ga("send", "pageview");
}

export function getUser() {
  const cookies = new Cookies();
  const user = cookies.get('user');
  const fbUser = user && cookies.get('fbuser');
  const gUser = user && cookies.get('guser');
  const aUser = user && cookies.get('auser');
  const displayName = 'PROFILE';
  if (fbUser) {
    reportToGoogleAnalytics();
    const { image = PROFILE_DEFAULT_IMAGE_PATH, name, id } = fbUser;
    return {
      provider: 'facebook',
      id,
      name,
      displayName,
      image,
      loggedIn: true,
    };
  }
  if (gUser) {
    reportToGoogleAnalytics();
    const {
      image = PROFILE_DEFAULT_IMAGE_PATH, name, email, id,
    } = gUser;
    return {
      provider: 'google',
      id,
      name,
      displayName,
      email,
      image,
      loggedIn: true,
      gUser,
    };
  }
  if (aUser) {
    reportToGoogleAnalytics();
    const { image = PROFILE_DEFAULT_IMAGE_PATH, name, id } = aUser;
    return {
      provider: 'apple',
      id,
      name,
      displayName,
      image,
      loggedIn: true,
    };
  }
  return {
    displayName: undefined,
    image: PROFILE_DEFAULT_IMAGE_PATH,
    loggedIn: false,
  };
}

export function getCognitoProfile() {
  if (typeof window === 'undefined') {
    return {};
  }
  const item = window.localStorage.getItem('cognitoProfile');
  if (!item) {
    return {};
  }
  return JSON.parse(item);
}

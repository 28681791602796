import { useRouter } from 'next/router';

export function useRedirLocation(gotoSubmit, goStraightToWheel) {
  const router = useRouter();

  const { query, locale } = router;
  const { game, slug } = query;
  const [, step] = slug || [];

  const basePath = `/${locale}/${game}`;
  if (gotoSubmit) {
    return `${basePath}/prediction/submit`;
  }

  if (goStraightToWheel) {
    return `${basePath}/prediction/wheel`;
  }

  if (!step) {
    return basePath;
  }

  return `${basePath}/step/${step}`;
}

import styled, { css } from 'styled-components';
import { colors, widths, mediaQueries } from '_constants/cssVars';

export const PCountryFlag = styled.img`
  width: 24px;
  height: 24px;
  border: 0;
  outline: none;
`;

export const PBodyWrapper = styled.section`
  display: flex;
  flex-direction: column;

  /* align-items: center; */

  box-sizing: border-box;
  background-color: ${colors.white};

  margin: 0;
  padding: 0 ${`calc((100% - ${widths.predictorContentMaxWidth}) / 2)`};
  @media ${mediaQueries.mobile} {
    padding: 0 20px;
  }
`;

export const PCountryName = styled.h5`
  font-size: 15px;
  margin: 0 15px;
  line-height: 1.2;
  color: ${colors.text};
`;

export const PStepsHeader = styled.h3`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  width: 100%;
  position: relative;
  color: ${colors.text};
`;

export const Bold = styled.span`
  font-weight: 500;
`;

export const buttonStyles = css`
  height: 55px;
  width: 300px;
  border-radius: 28px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const PSocialMediaIcon = styled.i`
  display: block;
  width: 24px;
  height: 24px;
  background: 100%/100% no-repeat;
  margin: 0 20px;
`;

export const PFacebookButtonCommon = css`
  ${buttonStyles}
  margin-top: 40px;
  background: ${colors.cobaltBlue} !important;
  color: ${colors.white} !important;
  & ${PSocialMediaIcon} {
    background-image: url("/img/ic_facebook.svg");
  }
`;

export const PFacebookButton = styled.button`
  ${PFacebookButtonCommon};
`;

export const PGoogleButton = styled.button`
  ${buttonStyles}
  background: ${colors.veryLightGray};
  color: ${colors.text2};
  & ${PSocialMediaIcon} {
    background-image: url("/img/ic_google.svg");
  }
`;

export const PTwitterButtonCommon = css`
  ${buttonStyles};
  background: ${colors.luminousBlue} !important;
  color: ${colors.white} !important;
  & ${PSocialMediaIcon} {
    background-image: url("/img/ic_twitter.svg");
  }
`;

export const PAppleButton = styled.button`
  ${buttonStyles}
  background: ${colors.black};
  color: ${colors.white};
  & ${PSocialMediaIcon} {
    background-image: url("/img/ic_apple.svg");
  }
`;

export const PInstagramButton = styled.button`
  ${buttonStyles}
  background: ${colors.veryLightGray};
  color: ${colors.text};
  & ${PSocialMediaIcon} {
    background-image: url("/img/ic_instagram.svg");
  }
`;

export const iconStyles = css`
  display: block;
  width: 24px;
  height: 24px;
  background: 100%/100% no-repeat;
`;
